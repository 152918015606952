@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .with-underline {
    @apply inline-block after:absolute after:bottom-0 after:left-0 after:bg-current after:h-[2px] after:w-0 hover:after:w-full after:transition-all;
  }

  .list {
    @apply my-3;
    & ul,
    & ol {
      @apply my-2;
    }
    & li {
      @apply relative pl-5 mb-1;
    }
  }

  .bulleted-list {
    & > li {
      &::before {
        content: "";
        @apply absolute bg-primary rounded-full w-1 h-1 left-1 top-[1rem];
      }
    }
  }

  .ordered-list {
    & > li {
      counter-increment: levelOne;
      &::before {
        @apply absolute font-bold text-primary left-0;
        content: counter(levelOne) ". ";
      }
      & ol li {
        @apply pl-4;
        counter-increment: levelTwo;
        &::before {
          content: counter(levelOne) "." counter(levelTwo) ". ";
        }
        & ol li {
          @apply pl-4;
          counter-increment: levelThree;
          &::before {
            content: counter(levelThree, lower-alpha) ". ";
          }
          & ol li {
            counter-increment: levelFour;
            &::before {
              content: counter(levelFour, lower-roman) ". ";
            }
          }
        }
      }
    }
  }
}

// google autocomplete styles
.pac-container {
  @apply font-p rounded-b-lg shadow-lg grayscale;
  &:after {
    content: none;
  }
  & .pac-item {
    @apply p-2;
  }
  & .pac-icon-marker {
    @apply hidden;
  }
}

// for tip tap
.ProseMirror {
  @apply bg-white p-3 border-black border-4 rounded-3xl min-h-[calc(200px+20vh)];

  // reset no-prose-list classes used in admin
  & ul {
    @apply list bulleted-list;
  }
  & ol {
    @apply list ordered-list;
  }
}

::selection {
  @apply bg-secondary text-white;
}

.grecaptcha-badge {
  @apply hidden #{!important};
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: calc(0.65rem + 0.3vw);
  @screen smmax {
    font-size: calc(0.75rem + 0.3vw);
  }
}

button[type="submit"] {
  @apply w-full;
}

main {
  @apply overflow-hidden;
}

a,
.href-style {
  @apply transition ease-in-out duration-300 cursor-pointer relative font-bold cursor-pointer;
}

body {
  @apply bg-white transition-all ease-in-out duration-300 font-light font-p text-base text-gray-700 max-w-full first:mt-0 last:mb-0;
}

p {
  @apply my-3;
}

p:first-of-type {
  @apply mt-0;
}

strong {
  @apply font-bold;
}

ul,
ol {
  @apply list;
}

ul {
  @apply bulleted-list;
}

ol {
  @apply ordered-list;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply text-gray-900 font-h font-bold mt-0 mb-3;
}

h1 {
  @apply text-headingXl;
}

h2 {
  @apply text-headingLg;
}

h3 {
  @apply text-headingBase;
}

h4,
h5,
h6 {
  @apply text-headingSm;
}

h2 + *,
h3 + *,
h4 + * {
  @apply mt-0;
}

hr {
  @apply border-t border-gray-300 my-6;
  & * {
    @apply mt-0;
  }
}

figure {
  @apply my-4;
  & > * {
    @apply my-0;
  }
  & figcaption {
    @apply text-gray-500 text-sm mt-2;
  }
}

img {
  @apply my-0;
}

pre {
  @apply text-gray-200 bg-gray-800 overflow-x-auto my-4 rounded px-3 py-2;
}

code {
  @apply text-primary font-normal;
  overflow-wrap: break-word;
  &:after {
    content: "\`";
  }
  &:before {
    content: "\`";
  }
}

pre code {
  @apply bg-transparent border-0 rounded-none p-0 font-normal;
  -webkit-text-fill-color: initial;
  &:before,
  &:after {
    content: none;
  }
}

video {
  @apply my-4;
}

table {
  @apply w-full table-auto text-left my-4 text-sm;
  & th,
  & td {
    @apply p-2;
  }
  & th {
    @apply align-bottom;
  }
  & td {
    @apply align-top;
  }
  & thead,
  & tfoot {
    @apply bg-gray-100 font-bold border-b border-gray-200;
  }
  & tbody {
    & tr {
      @apply border-b border-gray-200 last:border-b-0;
    }
  }
}

.notion-has-row-header {
  & tr td:first-of-type {
    @apply bg-gray-200 font-bold px-3 border-b border-b-white;
  }
}

blockquote {
  @apply font-light italic border-l-2 border-gray-200 my-3 pl-3;
  quotes: "\201C""\201D""\2018""\2019";
  & p {
    &:first-of-type:before {
      content: open-quote;
    }
    &:last-of-type:after {
      content: close-quote;
    }
  }
}

/**
 * nprogress
 */
#nprogress {
  @apply pointer-events-none;
}

#nprogress .bar {
  @apply bg-primary fixed top-0 left-0;
  z-index: 1031;
  width: 100%;
  height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
  @apply block absolute right-0 h-full opacity-100;
  width: 100px;
  box-shadow: 0 0 10px #29d, 0 0 5px #29d;
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  @apply block fixed;
  top: 15px;
  left: 15px;
  z-index: 1031;
}

#nprogress .spinner-icon {
  @apply animate-spin border-2 rounded-full border-primary border-b-0 border-r-0;
  width: 18px;
  height: 18px;
}

.nprogress-custom-parent {
  @apply overflow-hidden relative;
  & #nprogress .spinner,
  #nprogress .bar {
    @apply absolute;
  }
}

/**
 * Swiper js

 * REMOVED MOST OF WHAT I DON"T NEED
 *
 * Pagination
 * @see node_modules/swiper/components/pagination/pagination.scss
 */
.swiper-pagination {
  @apply absolute text-center transition-opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;
  &.swiper-pagination-hidden {
    opacity: 0;
  }
}
/* Common Styles */
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal > .swiper-pagination-bullets {
  bottom: 10px;
  left: 0;
  width: 100%;
}
/* Bullets */
.swiper-pagination-bullets-dynamic {
  overflow: hidden;
  font-size: 0;
  & .swiper-pagination-bullet {
    transform: scale(0.33);
    position: relative;
    &-active {
      transform: scale(1);
    }
    &-active-main {
      transform: scale(1);
    }
    &-active-prev {
      transform: scale(0.66);
    }
    &-active-prev-prev {
      transform: scale(0.33);
    }
    &-active-next {
      transform: scale(0.66);
    }
    &-active-next-next {
      transform: scale(0.33);
    }
  }
}
.swiper-pagination-bullet {
  @apply h-2 w-2 inline-block rounded-full bg-primary opacity-20;
  .swiper-pagination-clickable & {
    cursor: pointer;
  }

  &:only-child {
    @apply hidden #{!important};
  }
}
.swiper-pagination-bullet-active {
  @apply bg-primary opacity-100;
}
.swiper-horizontal {
  > .swiper-pagination-bullets {
    .swiper-pagination-bullet {
      margin: 0 4px;
    }
    &.swiper-pagination-bullets-dynamic {
      left: 50%;
      transform: translateX(-50%);
      white-space: nowrap;
      .swiper-pagination-bullet {
        transition: 200ms transform, 200ms left;
      }
    }
  }
  &.swiper-rtl > .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    transition: 200ms transform, 200ms right;
  }
}

/**
 * Swiper js
 *
 * Navigation
 * @see node_modules/swiper/components/navigation/navigation.scss
 */
:root {
  --swiper-navigation-size: 30px;
}

.swiper-button-prev,
.swiper-button-next {
  @apply text-black cursor-pointer z-10;
  margin-top: calc(-1 * var(--swiper-navigation-size) / 2);

  &.swiper-button-disabled {
    @apply cursor-auto pointer-events-none;
    opacity: 0.35;
  }
  & svg {
    height: var(--swiper-navigation-size);
  }
}

.swiper-button-lock {
  display: none;
}

/**
 * Swiper js
 *
 * base styles
 * @see node_modules/swiper/swiper.scss
 */

.swiper {
  @apply mx-auto relative overflow-hidden list-none p-0;
  /* Fix of Webkit flickering */
  z-index: 1;
}

.swiper-wrapper {
  @apply relative w-full h-full flex box-content;
  z-index: 1;
  transition-property: transform;
}
.swiper-android .swiper-slide,
.swiper-wrapper {
  transform: translate3d(0px, 0, 0);
}
.swiper-multirow > .swiper-wrapper {
  flex-wrap: wrap;
}
.swiper-multirow-column > .swiper-wrapper {
  flex-wrap: wrap;
  flex-direction: column;
}
.swiper-free-mode > .swiper-wrapper {
  transition-timing-function: ease-out;
  margin: 0 auto;
}
.swiper-pointer-events {
  touch-action: pan-y;
  &.swiper-vertical {
    touch-action: pan-x;
  }
}
.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: transform;
}
.swiper-slide-invisible-blank {
  visibility: hidden;
}
/* Auto Height */
.swiper-autoheight {
  &,
  .swiper-slide {
    height: auto;
  }

  .swiper-wrapper {
    align-items: flex-start;
    transition-property: transform, height;
  }
}

/**
 * React calendar
 */
.react-calendar {
  @apply font-p border-gray-200 border-2 bg-white w-[350px] max-w-full select-none;
  &--doubleView {
    @apply w-[700px];
    & .react-calendar__viewContainer {
      @apply flex;
      @apply -m-2;
      & > * {
        @apply w-1/2 m-2;
      }
    }
  }
  &__navigation {
    @apply flex h-[50px] font-bold;
    &__arrow {
      @apply min-w-[40px] bg-none bg-gray-100;
      &:disabled {
        @apply text-white bg-gray-200;
      }
    }
  }
  &__month-view {
    &__weekdays {
      @apply text-center uppercase font-bold text-sm;
      &__weekday {
        @apply p-2;
      }
    }
    &__weekNumbers {
      & .react-calendar__tile {
        @apply flex items-center justify-center text-sm font-bold;
      }
    }
    &__days {
      &__day {
        // &--weekend {
        //   @apply bg-error text-white #{!important};
        // }
        &--neighboringMonth {
          @apply text-gray-200;
        }
      }
    }
  }
  &__year-view,
  &__decade-view,
  &__century-view {
    & .react-calendar__tile {
      @apply px-4 py-2;
    }
  }
  &__tile {
    @apply max-w-full py-2 bg-none text-center border-gray-200;
    // &:enabled:hover,
    // &:enabled:focus {
    //   @apply bg-gray-200;
    // }
    &:disabled {
      @apply bg-gray-200 cursor-not-allowed;
    }
    // &--now {
    //   @apply bg-primary text-white;
    //   &:enabled:hover,
    //   &:enabled:focus {
    //     @apply bg-opacity-50;
    //   }
    // }
    &--hasActive,
    &--active {
      @apply border-success ring-2 ring-success ring-inset;
      // &:enabled:hover,
      // &:enabled:focus {
      //   @apply bg-success text-white;
      // }
    }
  }
}
