/* Most places are recommending font-display: optional; but it's not working right

Also font weights set below aren't necessarily the actual weights, just set like this for selection
*/
@font-face {
  font-family: "Montserrat";
  src: url("/fonts/Montserrat-400.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("/fonts/Montserrat-500.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("/fonts/Montserrat-700.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
